import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import React from 'react';
import { useState } from 'react';
import DeliveryModal from '@oos/core/components/DeliveryModal';
import ReturnsModal from './ReturnsModal';
import ContactModal from './ContactModal';
import "./Footer.css";

export default function Footer(props) {
    const [showDelivery, setShowDelivery] = useState(false);
    const [showReturn, setShowReturn] = useState(false);
    const [showContact, setShowContact] = React.useState(false);

    return(
        <Navbar variant="light" bg="" expand="sm">
            <Container className="Footer" fluid>
                <Nav className="me-auto">
                    <Row className="mx-1 my-3">
                        <Col align={'start'} className="Footer-text">
                            <Nav.Link eventKey="shop" href="https://shop.bumbletiger.com" target="_blank" rel="noreferrer">The Shop</Nav.Link>
                            <Nav.Link eventKey="design" as={Link} to="/design">Create My Own</Nav.Link>
                            <Nav.Link onClick={() => setShowReturn(true)}>Returns & Exchanges</Nav.Link>
                        </Col>
                        <Col>
                            <Button
                                className="mx-2 my-2 buttonStyle-black"
                                variant='outline-light'
                                onClick={() => setShowDelivery(true)}>
                                Delivery
                            </Button>
                            <Button
                                className="mx-2 my-2 buttonStyle-black"
                                variant='outline-light'
                                onClick={() => setShowContact(true)}>
                                Contact
                            </Button>
                        </Col>
                        <Col className="Footer-text" md={4} xs={12}>
                            Our products are made from GOTS certified organic cotton. They are printed on demand in a renewable energy powered factory in the UK.
                        </Col>
                    </Row>
                </Nav>
                <DeliveryModal show={showDelivery} onClose={() => setShowDelivery(false)}/>
                <ReturnsModal show={showReturn} onClose={() => setShowReturn(false)}></ReturnsModal>
                <ContactModal show={showContact} onClose={() => setShowContact(false)}/>
            </Container>
        </Navbar>
    )
}