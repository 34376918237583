import React from "react";
import Modal from 'react-bootstrap/Modal';
import "./DeliveryModal.css";

export default function DeliveryModal(props) {
    return(
        <Modal show={props.show} onHide={props.onClose}>
            <Modal.Header closeButton>
                <Modal.Title className="DeliveryModal-h1">Delivery</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2 className="DeliveryModal-h2">How fast are orders shipped?</h2>
                <p>Most orders are printed by our manufacturing partner Teemill on the same working day. Shipping times depend on where your order is being sent to and the delivery option selected at checkout. Please see options below. Once your order is being processed, you'll receive an email confirmation including an estimated delivery date. Public, bank or international holidays may affect shipping times.</p>
                <h2 className="DeliveryModal-h2">How much is shipping?</h2>
                <ul>
                    <li>UK Standard £4.00 (Delivery aim 2-3 days)</li>
                    <li>UK 1st Class £6.00 (Delivery aim 1-2 days)</li>
                    <li>UK Priority £9.50 (UK next day; ONLY for orders placed before 10 am GMT)</li>
                    <li>EU Standard £6.00 (Delivery aim 3-5 working days)</li>
                    <li>EU Tracked & Signed £15.00</li>
                    <li>International £7.00 (Delivery aim 6-7 working days)</li>
                    <li>International Tracked & Signed £15.50</li>
                </ul>
                {/* <p>We also offer free shipping on UK orders over £50, and EU/International orders over £100.</p> */}
                <h2 className="DeliveryModal-h2">Where do you ship to?</h2>
                <p>We deliver to the UK, most EU countries and the US. You can see the full list of countries that we ship to at checkout, just before you pay for your order.</p>
            </Modal.Body>
            <Modal.Footer className="me-auto">
                {/* <p>If you have any questions, please email hello@oilonshirt.com.</p> */}
            </Modal.Footer>
        </Modal>
    )
}