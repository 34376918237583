import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import "./Typewriter.css";

const Typewriter = () => {
    const [textIndex, setTextIndex] = useState(0);
    const [text, setText] = useState('');
    const [image, setImage] = useState('');

    const images = [
        { text: 'Cute fusion of a bumblebee and a tiger', image: 'bumbletiger1.webp' },
        { text: 'A cute capybara explores the galaxy', image: 'generative_capybara1.webp' },
        { text: 'A cat sunbathes in the garden', image: 'style_california.webp' },
    ];

    const typeText = (textToType) => {
        let index = -1;
        const intervalId = setInterval(() => {
        index++;
        setText((prevText) => {
        if (index < textToType.length) {
            return prevText + textToType[index];
        }
        return prevText;
            });
            if (index === textToType.length) {
                clearInterval(intervalId);
                setImage(images[textIndex].image);
            }
        }, 100);
        return () => clearInterval(intervalId);
    };

    useEffect(() => {
        const textToType = images[textIndex].text;
        setText('');
        return typeText(textToType);  // clean up inside
    }, [textIndex]);

    useEffect(() => {
        const intervalId = setInterval(() => {
        setTextIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(intervalId);
        }, []);

    return (
    <>
        <Row>
            <Col lg={{ span: 5, offset: 1 }} className='Typewriter-center'>
                <h1 className='my-4'>Your Story</h1>
                <span className="Typewriter-text Home-title">{text}</span>
                <span className="Typewriter-cursor Typewriter-text Home-title">...|</span>
            </Col>
            <Col lg={6}>
                { image ? <img src={image} alt="Typewriter" className="Typewriter-img"/> :
                <img src="bumbletiger_maskot.webp" className="Typewriter-img" /> }
            </Col>
        </Row>
    </>
    );
};

export default Typewriter;

