import './App.css';
import Footer from './Footer.js';
import Navigation from './Navigation.js';
import {IntlProvider} from 'react-intl';
import { DesignsProvider } from '@oos/core/components/DesignsContext';
import { Outlet } from "react-router-dom";


function App() {
  return (
    <div className="App">
		<IntlProvider locale='en'>
			<DesignsProvider>
				<Navigation />
				<Outlet />
				<Footer />
			</DesignsProvider>
		</IntlProvider>
    </div>
  );
}

export default App;
