import React from "react";
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Image } from 'react-bootstrap';
import "./HeroImage.css";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';


export default function HeroImage(props) {
    const HeroImage = [{image: 'bumbletiger_maskot.webp'}];

    return(
        <>
        <Card className="bg-transparent border-0">
            <Container fluid className="HeroImage-container-img">
                <Image className="BT-maskot HeroImage-img" src="bumbletiger_maskot.webp" />
                <Card.Img className="Hero-banner-img" src="bumbletiger_banner.webp" alt="background"/>
                <Card.Title className="HeroImage-title-text Home-title">IMAGINE</Card.Title>
                <Card.Title className='HeroImage-title-text-sm'>Your Print</Card.Title>
                
                <div className="HeroImage-design-button-div">
                    <div style={{height:"8vh"}} />
                    <Button className="buttonAnimate" as={Link} to="/design">CREATE NOW</Button>
                    <div className="Home-text">CUSTOM Printed STORY ART CLOTHING</div>
                </div>
            </Container>
        </Card>
        </>
        )
    }