import React from "react";
import "./HomeMain.css";
import HeroImage from "./HeroImage";
import Typewriter from "./Typewriter";
import GalleryCarousel from "./GalleryCarousel";
import ProductGallery from "./ProductGallery";


export const PRODUCTGALLERY = [
    {image: "kids_tee_pixel.png", desc:"Space Cat | Pixel Art Organic Tee", id: "1"},
    {image: "kids_jumper_poly.png", desc:"RhinoShark | Polygon Print Organic Jumper", id: "2"},
    {image: "kids_bag.png", desc:"SkeptiCat | Space Print | Organic Tote Bag", id: "3"},
]
export default function HomeMain(props) {
    return(
        <>
        <div style={{height:"2vh"}} />
        <HeroImage />
        <div className='Home-tabs'>
            <Typewriter />
            <div style={{height:"10vh"}} />
            <GalleryCarousel />
        </div>
        <ProductGallery gallery={PRODUCTGALLERY}/>
        <div style={{height:"2vh"}} />
        </>
    )
}

