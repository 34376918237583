import "./GalleryCarousel.css";
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

export default function GalleryCarousel () {
    return (
        <>
        <h1>Your Design</h1>
        <div style={{height:"6vh"}} />
        <div className="Gallery-div">
            <div className="Gallery-carousel">
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img" src="style_pixel.webp"></img>Pixel Art</Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img Gallery-product" src="kids_tee_pixel.png"></img></Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img" src="style_space.webp"></img>Space Art</Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img Gallery-product" src="kids_tee_whale.png"></img></Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img" src="style_polygon.webp"></img>Polygon Art</Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img Gallery-product" src="kids_jumper_poly.png"></img></Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img" src="style_triangle.webp"></img>Triangle Art</Button>
                <Button className="Gallery-img" variant="" as={Link} to="/design"><img className="img Gallery-product" src="kids_hoodie_lion.png"></img></Button>
            </div>
        </div>
        <h3 className="Home-text">What can You imagine?</h3>
        <div style={{height:"2vh"}} />
        <Button className="buttonAnimate buttonStyle-gold" as={Link} to="/design">Create Now</Button>
        <div style={{height:"4vh"}} />
        </>
    )
}