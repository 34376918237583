import Navbar from 'react-bootstrap/Navbar';
import './Navigation.css';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { Link } from "react-router-dom";
import { heartSVG, navToggleSVG } from '../SVGs';

export default function Navigation(props) {

    return (
        <>
            <Navbar collapseOnSelect fixed="top" bg="" expand="lg" className="Navigation m-auto">
                    <Container fluid>
                        <Navbar.Brand className="navbar NavHide m-auto" as={Link} to="/">
                            <img
                            src="bumbletiger_maskot.webp"
                            width="64px"
                            height="64px"
                            alt="Bumbletiger logo"
                            className='mb-2'
                            />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className="border-0">
                            <div className='Navigation-toggle'>
                                <img src="bumbletiger_maskot.webp" width="64px" height="64px" alt="Bumbletiger logo" className='mb-2'/>
                                {navToggleSVG}
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className='me-auto'>
                                <Nav.Link eventKey="home" as={Link} to="/" className="mx-3 menuItem NavHideHome">HOME</Nav.Link>
                                <Nav.Link eventKey="design" as={Link} to="/design" className="mx-3 menuItem">Create My Own</Nav.Link>
                                <Nav.Link eventKey="favourites" as={Link} to="/favourites" className="mx-3 menuItem">
                                    {heartSVG} MY DESIGNS
                                </Nav.Link>
                                <Nav.Link eventKey="shop" className="mx-3 menuItem" href="https://shop.bumbletiger.com" target="_blank" rel="noreferrer">THE SHOP</Nav.Link>
                            </Nav>
                            <Nav>
                                <div>
                                    <h1 className="Navigation-tagline Home-title">Kids Organic Cotton Clothes</h1>
                                    <h1 className="Navigation-tagnav">Free UK Shipping from £50</h1>
                                </div>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            {/* <Navbar sticky="top" className="Navigation-tagnav">
                <div className="">Free UK Shipping from £50</div>
                <h1 className="m-auto">CUSTOM STORY ART CLOTHING</h1>
            </Navbar> */}
        </>
    )
}